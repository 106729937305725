exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-disclaimer-js": () => import("./../../../src/pages/disclaimer.js" /* webpackChunkName: "component---src-pages-disclaimer-js" */),
  "component---src-pages-ebook-js": () => import("./../../../src/pages/ebook.js" /* webpackChunkName: "component---src-pages-ebook-js" */),
  "component---src-pages-ebook-subscribed-js": () => import("./../../../src/pages/ebook-subscribed.js" /* webpackChunkName: "component---src-pages-ebook-subscribed-js" */),
  "component---src-pages-freshly-inventory-js": () => import("./../../../src/pages/freshly-inventory.js" /* webpackChunkName: "component---src-pages-freshly-inventory-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-simple-bundles-js": () => import("./../../../src/pages/simple-bundles.js" /* webpackChunkName: "component---src-pages-simple-bundles-js" */),
  "component---src-pages-terms-js": () => import("./../../../src/pages/terms.js" /* webpackChunkName: "component---src-pages-terms-js" */),
  "component---src-pages-using-ssr-js": () => import("./../../../src/pages/using-ssr.js" /* webpackChunkName: "component---src-pages-using-ssr-js" */),
  "component---src-templates-using-dsg-js": () => import("./../../../src/templates/using-dsg.js" /* webpackChunkName: "component---src-templates-using-dsg-js" */)
}

